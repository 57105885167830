export function snapshotIntakeInternalTools(args) {
  const { internalToolRatings, internalToolComments, formLength } = args;

  return {
    internalToolRatings,
    internalToolComments,
    focusedInternalToolId: null,
    init() {
      this.focusNextRow();

      this.$watch("internalToolRatings", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow)
          this.focusedInternalToolId = parseInt(nextRow.dataset.tool);
      });
    },
    formValid() {
      return Object.values(this.internalToolRatings).length >= formLength;
    },
    isFilled(internalToolId) {
      return this.internalToolRatings[internalToolId];
    },
    isFilledOrFocused(internalToolId) {
      return (
        this.isFilled(internalToolId) ||
        internalToolId == this.focusedInternalToolId
      );
    },
    addComment(internalToolId) {
      if (!this.internalToolComments[internalToolId]) {
        this.internalToolComments[internalToolId] = {};
      }

      this.internalToolComments[internalToolId].open = true;

      this.$nextTick(() => {
        document.getElementById(`comment-${internalToolId}`).focus();
      });
    },
    removeComment(internalToolId) {
      if (!this.internalToolComments[internalToolId]) return;

      delete this.internalToolComments[internalToolId];
    },
    commentOpen(internalToolId) {
      return (
        this.internalToolComments[internalToolId]?.open ||
        this.internalToolComments[internalToolId]?.value
      );
    },
  };
}
