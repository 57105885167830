import { ScatterChart } from "@get-dx/d3-charts";
import { setQueryStringParam, objectToQueryString } from "../../shared/utils";
import * as d3 from "d3";
import { chartTooltipHtml } from "../chartTooltipHtml";

const CHART_TYPES = [
  { name: "Speed", slug: "speed" },
  { name: "Ease of delivery", slug: "ease" },
  { name: "Quality", slug: "reliability" },
  { name: "Weekly time loss", slug: "time_loss" },
];

const CUSTOM_DRIVER_BASELINE = 50;

export function keyDriversChart(args) {
  const { baseUrl, minResponseThreshold } = args;

  const queryParams = new URLSearchParams(window.location.search);

  const chartTypeSlug = queryParams.get("metric") ?? CHART_TYPES[0].slug;

  return {
    isHidden: false,
    chartTypes: CHART_TYPES,
    chartTypeSlug,
    valuesToImprove: [],
    correlations: null,
    minResponseThreshold,
    init() {
      if (!this.isHidden) {
        this.renderChart();
      }
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      setQueryStringParam("squad_ids", squadIds);
      this.renderChart();
    },
    get chartTypeName() {
      const selectedChart = this.chartTypes.find(
        (chart) => chart.slug === this.chartTypeSlug,
      );

      if (
        this.chartTypeSlug == "reliability" &&
        window.currentAccount?.feature_flags?.use_reliability_label
      ) {
        return "Reliability";
      }

      return selectedChart.name;
    },
    chartName(chartItem) {
      if (
        chartItem.slug == "reliability" &&
        window.currentAccount?.feature_flags?.use_reliability_label
      ) {
        return "Reliability";
      }

      return chartItem.name;
    },
    selectChart(chartTypeSlug) {
      this.chartTypeSlug = chartTypeSlug;

      // Update url query param without reloading the page
      const url = new URL(window.location);
      url.searchParams.set("metric", chartTypeSlug);
      window.history.pushState({}, "", url);

      this.renderChart();
    },
    async fetchResultItems() {
      const paramsObj = {
        squad_ids: this.squadIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
      };

      const queryParams = objectToQueryString(paramsObj);

      const url = `${baseUrl}?${queryParams}`;

      const resp = await fetch(url);
      const data = await resp.json();

      const highestResponseCount = data?.result_items?.reduce((max, i) => {
        return i.count > max ? i.count : max;
      }, -Infinity);
      if (highestResponseCount < minResponseThreshold) this.isHidden = true;
      else this.isHidden = false;

      return data;
    },
    async renderChart() {
      const data = await this.fetchResultItems();
      if (data.correlations) {
        this.correlations = data.correlations;
      } else {
        this.isHidden = true;
      }

      if (this.isHidden) return;

      const chartElement = document.getElementById("key-drivers-chart");

      if (!chartElement) return;

      const correlationsMap = new Map();

      for (const x of this.correlations) {
        correlationsMap.set(x.factor_id, {
          ease: x.ease,
          speed: x.speed,
          reliability: x.reliability,
          time_loss: x.time_loss,
        });
      }

      const values = data.result_items
        .filter((item) => !item.outcome)
        .map((item) => ({
          x: correlationsMap.get(item.id)[this.chartTypeSlug],
          y: item.vs_75th ?? item.score - CUSTOM_DRIVER_BASELINE,
          driver: item.name,
          prompt: item.prompt,
          count: item.count,
          one_count: item.one_count,
          two_count: item.two_count,
          three_count: item.three_count,
          four_count: item.four_count,
          five_count: item.five_count,
          scale_points: item.scale_points,
        }));

      // Find the 5 drivers with the highest importance
      this.valuesToImprove = [...values]
        .sort((a, b) => d3.descending(a.x, b.x))
        .slice(0, 5);

      let [xMin, xMax] = d3.extent(values.map((d) => d.x));
      let [yMin, yMax] = d3.extent(values.map((d) => d.y));

      // Buffer the charts so no points are plotted on the axis lines
      const xBuffer = (Math.abs(xMax + xMin) / 2) * 0.08;
      const yBuffer = (Math.abs(yMax + yMin) / 2) * 0.1;

      xMax += xBuffer * 5;
      yMax += yBuffer * 5;
      xMin -= xBuffer;
      yMin -= yBuffer * 5;

      const xMid = (xMax + xMin) / 2;
      const yMid = (yMax + yMin) / 2;

      // Clear existing chart before we render
      chartElement.innerHTML = "";

      const chart = new ScatterChart({
        elChart: chartElement,
        xAxisTickLabelFormat: () => "",
        yAxisTickLabelFormat: () => "",
        matrix: [xMid, yMid],
        dotRadius: 3,
        highlightedQuadrant: 4,
        showYAxisTicks: false,
        showXAxisTicks: false,
        showXAxisTickLabels: false,
        showYAxisTickLabels: false,
        axis: {
          x: {
            label: "Correlation",
            min: xMin,
            max: xMax,
          },
          y: {
            label: "Performance",
            min: yMin,
            max: yMax,
          },
        },
        values,
        labelTextFormat: (d) => d.driver,
        tooltipHtml: chartTooltipHtml.keyDrivers,
      });

      chart.redraw();
    },
  };
}
