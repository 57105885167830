export function studiesResponseSegmentSelect(args) {
  const { endpoint, studyBatch, selectedSnapshot, onChange } = args;

  return {
    loaded: false,
    expanded: false,
    factors: [],
    internalTools: [],
    behavioralQuestions: [],
    options: [],
    searchResults: [],
    stagedItem: undefined,
    stagedSegment: undefined,
    selectedSnapshot,
    selectedItem: undefined, // For keeping track of actual selection while dropdown is being interacted with
    selectedSegment: undefined, // For keeping track of actual selection while dropdown is being interacted with
    view: "items", // "items" || "segments" || "snapshots"
    init() {
      this.loadItems(this.loadSelectedItem.bind(this));
    },
    loadSelectedItem() {
      // Load selected segment
      let selectedItem;
      let selectedSegment;

      const {
        snapshot_response_segment: segment,
        snapshot_response_segment_item_type: itemType,
        snapshot_response_segment_item_id: itemId,
      } = studyBatch;

      if (itemType == "Factor") {
        selectedItem = this.factors.items.find((i) => i.id === itemId);
        selectedSegment = this.factors.segments.find((s) => s.id === segment);
      } else if (itemType == "InternalTool") {
        selectedItem = this.internalTools.items.find((i) => i.id === itemId);
        selectedSegment = this.internalTools.segments.find(
          (s) => s.id === segment,
        );
      } else if (itemType == "BehavioralQuestion") {
        selectedItem = this.behavioralQuestions.items.find(
          (i) => i.id === itemId,
        );
        selectedSegment = this.behavioralQuestions.segments.find(
          (s) => s.id === segment,
        );
      }

      if (selectedItem && selectedSegment) {
        this.selectedItem = selectedItem;
        this.selectedSegment = selectedSegment;

        if (this.selectedSegment.label.includes("[TARGET_RANGE_LABEL]")) {
          this.selectedSegment.label = this.selectedSegment.label.replace(
            "[TARGET_RANGE_LABEL]",
            `(${this.selectedItem.target_label})`,
          );
        }
      }
    },
    loadItems(callback) {
      this.loaded = false;

      fetch(`${endpoint}?snapshot_id=${this.selectedSnapshot.id}`)
        .then((response) => response.json())
        .then((data) => {
          this.factors = data.factors;
          this.internalTools = data.internal_tools;
          this.behavioralQuestions = data.behavioral_questions;

          // Load options array for select dropdown
          let options = [];
          if (this.factors.items.length) {
            options.push({ is_group_label: true, name: "Drivers", id: "0" });
            options = options.concat(this.factors.items);
          }
          if (this.internalTools.items.length) {
            options.push({ is_group_label: true, name: "CSAT", id: "1" });
            options = options.concat(this.internalTools.items);
          }
          if (this.behavioralQuestions.items.length) {
            options.push({ is_group_label: true, name: "Worfklows", id: "2" });
            options = options.concat(this.behavioralQuestions.items);
          }
          this.options = options;
          this.searchResults = this.options;

          this.loaded = true;

          if (callback) callback();
        });
    },
    toggleDropdown() {
      if (this.expanded) return this.unfocus();
      this.focus();
    },
    focus() {
      this.searchResults = this.options;
      this.view = "items";
      this.expanded = true;

      this.stagedItem = this.selectedItem;
      this.stagedSegment = this.selectedSegment;

      this.$nextTick(() => {
        this.$refs.searchInput?.focus();
      });
    },
    unfocus() {
      if (!this.expanded) return;

      this.stagedItem = null;
      this.stagedSegment = null;
      this.expanded = false;

      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.value = "";
          this.$refs.searchInput?.blur();
        }
      });
    },
    segmentsForItem(item) {
      if (item?.type == "Factor") {
        return this.factors.segments;
      } else if (item?.type == "InternalTool") {
        return this.internalTools.segments;
      } else if (item?.type == "BehavioralQuestion") {
        return this.behavioralQuestions.segments.map((segment) => {
          const copy = JSON.parse(JSON.stringify(segment)); // Deep clone question so we don't mutate object

          copy.label = copy.label.replace(
            "[TARGET_RANGE_LABEL]",
            `(${item.target_label})`,
          );

          return copy;
        });
      }

      return [];
    },
    selectItem(item) {
      this.stagedItem = item;
      this.view = "segments";
    },
    selectSegment(segment) {
      this.selectedItem = this.stagedItem;
      this.selectedSegment = segment;

      this.emitChange();
    },
    isSelected(item, segment) {
      if (!item || !segment) return false;

      return (
        this.selectedItem?.id == item.id &&
        this.selectedSegment?.id == segment.id
      );
    },
    deselect() {
      this.selectedItem = null;
      this.selectedSegment = null;
      this.unfocus();

      this.emitChange();
    },
    search() {
      const searchTerm = this.$refs.searchInput.value;

      if (searchTerm.length > 0) {
        const matchingOptions = this.options.filter((option) => {
          return (
            option.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !option.is_group_label
          );
        });

        this.searchResults = matchingOptions;
        return;
      }

      this.searchResults = this.options;
    },
    selectSnapshot(snapshot) {
      this.selectedSnapshot = snapshot;

      this.loadItems();

      this.selectedItem = undefined;
      this.selectedSegment = undefined;
      this.stagedItem = undefined;
      this.stagedSegment = undefined;

      this.emitChange();

      this.view = "items";
    },
    isSelectedSnapshot(id) {
      return this.selectedSnapshot?.id == id;
    },
    emitChange() {
      onChange({
        item: this.selectedItem,
        segment: this.selectedSegment,
        snapshot_id: this.selectedSnapshot?.id,
      });
    },
  };
}
