import { LineChart } from "@get-dx/d3-charts";
import {
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
} from "../../shared/utils";
import { getQueryParam } from "../../lib/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

export function snapshotAttrition({ url, canViewActuals }) {
  return {
    attritionModalOpen: false,
    url,
    attritionTabs: [{ tab: "risk" }],
    attritionData: null,
    tabDataLoaded: false,
    selectedAttritionTab: "",
    selectedAttritionPeriod: "6mo",
    selectedAttritionInterval: "mo",
    init() {
      const result = getQueryParam("attrition");
      const tab = getQueryParam("adt");
      const period = getQueryParam("adp");
      const interval = getQueryParam("adi");

      if (canViewActuals) this.attritionTabs.push({ tab: "actuals" });

      if (period) this.setPeriod(period);
      if (interval) this.setInterval(interval);

      if (result) this.showAttrition();
      if (tab) this.setTab(tab);
      else this.setTab(this.attritionTabs[0]?.tab);
    },
    showAttrition() {
      this.attritionModalOpen = true;
      setQueryStringParam("attrition", true);
      this.fetchAttritionData();
    },
    hide() {
      deleteQueryStringParam("adt");
      deleteQueryStringParam("adp");
      deleteQueryStringParam("adi");
      deleteQueryStringParam("attrition");
      this.attritionModalOpen = false;
    },
    formatDate(d) {
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
      }).format(d);
    },
    setTab(tab) {
      this.selectedAttritionTab = tab;
      this.refreshCharts(tab);
      setQueryStringParam("adt", tab);
    },
    setPeriod(period) {
      setQueryStringParam("adp", period);
      this.selectedAttritionPeriod = period;
      if (this.attritionData) this.fetchAttritionData();
    },
    setInterval(interval) {
      setQueryStringParam("adi", interval);
      this.selectedAttritionInterval = interval;
      if (this.attritionData) this.fetchAttritionData();
    },
    fetchAttritionData() {
      this.tabDataLoaded = false;
      setQueryStringParam("adt", this.selectedAttritionTab);

      if (!this.url) return;

      const fullUrl = `${this.url}${this.queryParams()}`;

      return fetch(fullUrl)
        .then((resp) => resp.json())
        .then((data) => {
          this.attritionData = data;
          this.tabDataLoaded = true;
          this.refreshCharts(this.selectedAttritionTab);
        });
    },
    queryParams() {
      const params = {
        p: this.selectedAttritionPeriod,
        i: this.selectedAttritionInterval,
        tag_ids: getQueryParam("tag_ids"),
      };
      return `?${objectToQueryString(params)}`;
    },
    refreshCharts(tab) {
      if (this.attritionData?.chart_data.length > 0 && tab == "risk") {
        setTimeout(() => this.buildRiskChart(), 50);
      }
      if (this.attritionData?.data?.length > 0 && tab == "actuals") {
        setTimeout(() => this.buildActualsChart(), 50);
      }
    },
    buildRiskChart() {
      const chartData = this.attritionData.chart_data;
      const chartMax = Math.max(...chartData.map((obj) => obj.value)) || 0;
      const elChart = document.getElementById(`chartattr-risk-chart`);
      if (!elChart) return;
      elChart.innerHTML = null;

      if (chartData.length > 1) {
        const chartParams = {
          elChart,
          startDate: chartData[0].date,
          endDate: chartData[chartData.length - 1].date,
          yAxisMin: 0,
          yAxisMax: Math.round(chartMax * 1.2),
          tooltipHtml(d) {
            const params = {
              heading: "At-risk of attrition",
              d: {
                label: d.label,
                value: d.value,
                formattedValue: `${d.value}% of your organization`,
                contributorCount: d.contributor_count,
              },
            };

            return chartTooltipHtml.attritionRisk(params);
          },
          values: chartData,
        };

        new LineChart(chartParams);
      }
    },
    buildActualsChart() {
      const { data } = this.attritionData;
      if (!data) return;
      const chartMax = Math.max(...data.map((obj) => obj.value)) || 0;

      const elChart = document.getElementById(`chartattr-actuals-chart`);
      if (!elChart) return;
      elChart.innerHTML = null;

      const chartParams = {
        elChart,
        startDate: data[0].date,
        endDate: data[data.length - 1].date,
        yAxisMin: 0,
        yAxisMax: chartMax * 1.2,
        tooltipHtml(d) {
          const params = {
            heading: "Actuals",
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          return chartTooltipHtml.default(params);
        },
        values: data,
      };

      new LineChart(chartParams);
    },
  };
}
