import { objectToQueryString } from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";
import { formatTime } from "../../datacloud/shared/formatters";

export function snapshotResultsLineChart(args) {
  const { endpoint, name, filters, chartIndex, chartData, unit } = args;

  let chart = null;

  return {
    displayable: true,
    chartData,
    chartSql: null,
    loading: false,
    variant: null,
    showQueryModal: false,
    sqlModalChartTitle: "",
    rawSql: null,
    unit,
    setVariant(variant) {
      this.variant = variant;
      this.reloadChart();
    },
    reloadChart: function () {
      if (!endpoint) return;

      const filtersQueryString = objectToQueryString({ ...filters });
      this.loading = true;
      // Add a snapshot_results param to avoid saving the filters to the session
      fetch(
        `${endpoint}${filters.chart_name ? "/bar_chart" : ""}${
          "?" +
          filtersQueryString +
          (this.variant ? "&variant=" + this.variant : "")
        }&snapshot_results=true`,
      )
        .then((response) => response.json())
        .then((response) => {
          chartData.elChart = document.getElementById(
            `linechart-snapshot-results-${chartIndex}`,
          );
          chartData.elChart.innerHTML = null;

          chart = new window.LineChart(chartData);

          let data;
          if (filters.chart_name) {
            data = response.data;
          } else {
            data = response;
          }

          this.chartData = data.chart;

          if (this.unit) {
            const formatIfNeeded = (value) => {
              if (this.unit.trim() === "minutes") {
                const result = formatTime(value);
                return {
                  value: result.value,
                  unit: ` ${result.unit}`,
                };
              }
              return {
                value: value,
                unit: this.unit,
              };
            };

            const formattedTotal = formatIfNeeded(this.chartData.total);

            this.chartData = {
              ...this.chartData,
              total: formattedTotal.value,
              unit: formattedTotal.unit,
              data: this.chartData?.data?.map((d) => {
                const formattedData = formatIfNeeded(d.value);
                return {
                  ...d,
                  displayValue: formattedData.value,
                  unit: formattedData.unit,
                };
              }),
            };
          }

          this.sqlModalChartTitle = this.chartData.title;
          this.rawSql = data.sql;
        })
        .then(() => this.loadChart())
        .catch(() => {
          // no op
        });

      // This function is invoked in the DOM with display logic. Always return true
      return true;
    },
    loadChart: function () {
      // Avoids using this.chartData in the tooltip function
      const chartData = this.chartData;

      chart.yAxisMin = 0;
      chart.yAxisMax = 100;
      chart.values = chartData.data;
      this.displayable = chart.values ? chart.values.length > 0 : false;
      chart.tooltipHtml = function (d) {
        const params = {
          heading: name,
          d: {
            label: d.label,
            formattedValue: `${d.displayValue}${d.unit}`,
          },
        };

        return chartTooltipHtml.default(params);
      };
      chart.startDate = chartData.startDate;
      chart.endDate = chartData.endDate;
      chart.yAxisMax = Math.max(...chartData.data.map((v) => v.value)) * 1.2;
      chart.redraw();
      this.loading = false;
    },
  };
}
