import Papa from "papaparse";
import { postData } from "../../shared/utils";

export function adminOrgCsvs(args) {
  const { account } = args;

  return {
    loading: false,
    showInstructions: false,
    account,
    isDragging: false,
    hasError: false,
    file: null,
    headers: [],
    rows: [],
    sampledRows: [],
    fallbacksAdded: 0,
    fields: [
      { key: "name", label: "User name" },
      { key: "email", label: "User email" },
      { key: "team_name", label: "Team name" },
      { key: "mgr_name", label: "Manager name" },
      { key: "mgr_email", label: "Manager email" },
    ],
    selectedColumns: {
      name: "PREFERRED_NAME",
      email: "WORK_EMAIL",
      team_name: "SUPERVISORY_ORGANIZATION",
      mgr_name: "MANAGER",
      mgr_email: "MANAGER EMAIL",
    },
    showPreview: false,
    teamsPreview: {},
    squads: [],
    expandedSquadsIds: {},
    init() {},
    handleDrop(event) {
      this.hasError = false;
      event.preventDefault();
      this.file = event.dataTransfer.files[0];
      this.isDragging = false;
      this.validateFile();
    },
    processFile() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.parseCSV(csvContent);
        };
        reader.readAsText(this.file);
      }
    },
    validateFile() {
      this.errorMessage = "";
      if (this.file) {
        // Check the MIME type or extension
        const mimeType = this.file.type;
        const fileName = this.file.name;

        // Validate MIME type or file extension
        if (
          mimeType !== "text/csv" &&
          mimeType !== "application/vnd.ms-excel" &&
          !fileName.endsWith(".csv")
        ) {
          this.errorMessage = "Invalid file type. Please upload a CSV file.";
          this.hasError = true;
          this.file = null;
          return;
        }

        this.processFile(); // Process only if it's a valid CSV file
      }
    },
    parseCSV(csvContent) {
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          this.headers = result.meta.fields;
          this.rows = result.data;
          this.sampleRandomRows();
        },
        skipEmptyLines: true,
      });
    },
    sampleRandomRows() {
      if (this.rows.length > 0) {
        // Shuffle the rows and take a random sample of 20 rows
        const shuffled = [...this.rows].sort(() => 0.5 - Math.random());
        this.sampledRows = shuffled.slice(0, 20);
      }
    },
    addFallbackManagerField() {
      const fallbackIndex = this.fallbacksAdded + 1;
      this.fields.push({
        key: `fallback_mgr_name_${fallbackIndex}`,
        label: `Fallback Manager name ${fallbackIndex}`,
        canDelete: true,
      });
      this.fields.push({
        key: `fallback_mgr_email_${fallbackIndex}`,
        label: `Fallback Manager email ${fallbackIndex}`,
        canDelete: true,
      });
      this.fallbacksAdded += 1;
    },
    removeFallbackManagerSet() {
      if (this.fallbacksAdded > 0) {
        this.fields = this.fields.slice(0, -2);
        this.fallbacksAdded -= 1;
      }
    },
    preview() {
      const employees = this.rows.map((row) => {
        return {
          name: row[this.selectedColumns["name"]],
          email: row[this.selectedColumns["email"]],
          team: row[this.selectedColumns["team_name"]],
          mgr_name: row[this.selectedColumns["mgr_name"]], // TODO: fallback logic here to traverse back through the fallbacks if they don't exist
          mgr_email: row[this.selectedColumns["mgr_email"]],
        };
      });

      const result = this.buildOrgHierarchy(employees);

      this.teamsPreview = result;
      this.squads = this.newFlattenHierarchy(result);
      this.showPreview = true;
    },
    buildOrgHierarchy(employees) {
      // Create a map of all employees by their email
      const employeeMap = new Map(employees.map((emp) => [emp.email, emp]));

      // Find the top-level manager(s)
      const topManagers = employees.filter(
        (emp) => !employeeMap.has(emp.mgr_email),
      );

      let teamIdCounter = 1;

      // Function to recursively build the hierarchy
      function buildHierarchy(manager, depth = 1, parent_id) {
        const temp_id = `T${teamIdCounter++}`;
        const subordinates = employees.filter(
          (emp) => emp.mgr_email === manager.email,
        );
        const subTeams = subordinates.map((sub) =>
          buildHierarchy(sub, depth + 1, temp_id),
        );

        const obj = {
          name: manager.name,
          email: manager.email,
          mgr_email: manager.mgr_email,
          team: manager.team,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          subTeams: subTeams,
        };

        return obj;
      }

      // Group top managers by team
      const teamGroups = topManagers.reduce((groups, manager) => {
        const team = manager.team;
        if (!groups[team]) {
          groups[team] = [];
        }
        groups[team].push(manager);
        return groups;
      }, {});

      // Build the hierarchy for each team
      const hierarchy = Object.entries(teamGroups).map(([team, managers]) => {
        const temp_id = `T${teamIdCounter++}`;

        return {
          team: team,
          depth: 0,
          temp_id: temp_id,
          is_parent: true,
          subTeams: managers.map((manager) =>
            buildHierarchy(manager, 1, temp_id),
          ),
        };
      });

      return hierarchy.sort((a, b) => {
        // Sort by is_parent first
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        // If is_parent is the same, sort alphabetically by team
        return a.team.localeCompare(b.team);
      });
    },
    newFlattenHierarchy(hierarchy) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        flatArray.push({
          name: node.name,
          email: node.email,
          mgr_email: node.mgr_email,
          team: node.team,
          temp_id: node.temp_id,
          parent_id: parentId,
          is_parent: node.is_parent,
          depth: node.depth,
          members: node.subTeams.length,
          subTeamName: node.subTeams.map((t) => t.team)?.[0],
        });

        // Recursively flatten the subTeams (children)
        node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    get treeSquads() {
      this.squads.map((s) => {
        return s;
      });

      const squadsToReturn = this.squads?.filter((s) => {
        const value =
          s.depth == 0 ||
          s.expanded == true ||
          this.expandedSquadsIds[s.parent_id] == true;

        return value;
      });

      return squadsToReturn;
    },
    toggleSquad(targetSquad) {
      this.squads = this.squads.map((squad) => {
        if (targetSquad.temp_id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
        }

        if (targetSquad.temp_id === squad.temp_id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (targetSquad.expanded) {
            this.expandedSquadsIds[targetSquad.temp_id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.temp_id];
          }
        }

        return squad;
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.temp_id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    submit() {
      console.log("submitting....", this.squads);

      const url = "/admin/org_csvs";

      postData(url, { squads: this.squads }).then((res) => {
        console.log(`res:`, res);
      });
    },
    downloadJSON() {
      const json = JSON.stringify(this.squads, null, 2); // Convert squads to JSON
      const blob = new Blob([json], { type: "application/json" }); // Create a Blob
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href to the Blob URL
      a.download = "squads.json"; // Set the download attribute
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor from the document
      URL.revokeObjectURL(url); // Clean up the URL object
    },
  };
}
